import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { getCurrentDomain } from "../utils";
import loginPageBackground from "../images/login_background.png";
import app_logo from "../images/new_logo.jpeg";
import './login.css';
import closeIcon from '../images/close_button.svg';
import '../components/style.css';
import BeatLoaderSpinner from "../components/LoadingPage/BeatLoaderSpinner";
import { APP_ACTIVE_COLOR } from "../constant";

function LoginPage() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loginError, setloginError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setloginError('');
    try {
        const result = await fetch(getCurrentDomain() + '/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userName: email,
              password: password,
            }),
          });
          if (result.status === 200) {
            window.location.href = '/';
          } else {
            //get error message
            const data = await result.json();
            if (data.message === "Invalid username")
            {
              setloginError('如需新账号登录，请联系管理员新增账号');
            } else if (data.message === "Invalid password"){
              setloginError('如忘记密码，请联系管理员重置密码');
            }
            setShowErrorModal(true);
          }
    }
    catch (error) {
        console.log('error', error);
    }
    finally{
        setIsLoading(false);
    }
  };

  const renderLoginErrorForm = () => {
    if (showErrorModal) {
      console.log('showErrorModal', showErrorModal);
      return renderLoginFail();
    }
  }

  const renderLoginFail = () => {
    return (
        <div className="user-management-modal">
            <div className="duplicate-account-wrapper" style={{
              alignItems: 'center',
            }}> 
            <div style={{
                marginTop: 122,
                fontSize: 20,
                fontWeight: 400,
                display: 'flex',
                justifyContent: 'center',
            }}>
                密码错误，请重新输入
            </div>
            <div style={{
                marginTop: 29,
                fontSize: 20,
                fontWeight: 400,
                display: 'flex',
                justifyContent: 'center',

            }}>
                {loginError}
            </div>
              <div className="user-management-modal-button"  onClick={() => {setShowErrorModal(false)}} style={{
                        marginLeft: 0,
                        marginTop: 62
                    }}>
                        取消
              </div>
            {renderButtonCloseModal()}
            </div>
           
        </div>

    )
  
  }

  const renderButtonCloseModal = () => {
    return (
        <div className="user-management-modal-close" onClick={() => setShowErrorModal(false)}>
            <img className="user-management-modal-close-icon" src={closeIcon} />
        </div>
    )
  }

  const renderLoadingPage = () => {
    return (
        <div className='col-12' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            display: isLoading ? 'flex' : 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000000000000
        }}>
            <BeatLoaderSpinner />
        </div>
    )
}

  

  return (
    <div className="login-page" style={{
        backgroundImage: `url(${loginPageBackground})`,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100vh'
    }}>
      <div className="login_logo" style={{
          background: `url(${app_logo})`,
          // backgroundColor: APP_ACTIVE_COLOR
        }}>
      </div>
      
      <div className="login-form">
        <div className="login-title">
        欢迎来IncreTech AIGC应用平台
        </div>
        <div className="login-field">
          <div className="field-title">
            请输入账号
          </div>
          <div>
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="请输入账号"
            />
          </div>
        </div>
        <div className="login-field" style={{
          marginTop: '27px',
        }}>
          <div className="field-title">
          请输入密码
          </div>
          <div>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="请输入密码"
            />
          </div>
        </div>
        <div className="login-button" onClick={loginSubmit}>
          登录
        </div>

      </div>

      {renderLoginErrorForm()}
      {renderLoadingPage()}
    </div>
  );
}
export default LoginPage;
