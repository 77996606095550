export const MOCK_USERS = [
    {
      "ActiveStatus": true,
      "CreatedDate": "Fri, 26 Jul 2024 19:42:35 GMT",
      "Role": "User",
      "id": 2,
      "userName": "HiDream",
      "userNickName": "HiDream"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Tue, 30 Jul 2024 11:50:24 GMT",
      "Role": "User",
      "id": 3,
      "userName": "EASTREME",
      "userNickName": "EASTREME"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Tue, 30 Jul 2024 11:51:25 GMT",
      "Role": "User",
      "id": 4,
      "userName": "xiaoxiong",
      "userNickName": "xiaoxiong"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 08 Aug 2024 10:33:35 GMT",
      "Role": "User",
      "id": 5,
      "userName": "jiangweiming",
      "userNickName": "VM江先生"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Tue, 13 Aug 2024 09:49:25 GMT",
      "Role": "User",
      "id": 6,
      "userName": "oscartest",
      "userNickName": "oscartest"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 15 Aug 2024 13:48:38 GMT",
      "Role": "User",
      "id": 7,
      "userName": "SGMW1",
      "userNickName": "SGMW1"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 15 Aug 2024 13:48:48 GMT",
      "Role": "User",
      "id": 8,
      "userName": "SGMW2",
      "userNickName": "SGMW2"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 15 Aug 2024 13:48:57 GMT",
      "Role": "User",
      "id": 9,
      "userName": "SGMW3",
      "userNickName": "SGMW3"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 22 Aug 2024 09:20:10 GMT",
      "Role": "User",
      "id": 10,
      "userName": "GTMC-DS",
      "userNickName": "GTMC-DS"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 22 Aug 2024 11:44:59 GMT",
      "Role": "User",
      "id": 11,
      "userName": "Bears-AIGC1",
      "userNickName": "Bears-AIGC1"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 22 Aug 2024 11:45:07 GMT",
      "Role": "User",
      "id": 12,
      "userName": "Bears-AIGC2",
      "userNickName": "Bears-AIGC2"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 22 Aug 2024 11:45:15 GMT",
      "Role": "User",
      "id": 13,
      "userName": "Bears-AIGC3",
      "userNickName": "Bears-AIGC3"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 22 Aug 2024 11:45:24 GMT",
      "Role": "User",
      "id": 14,
      "userName": "Bears-AIGC4",
      "userNickName": "Bears-AIGC4"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Thu, 22 Aug 2024 12:29:08 GMT",
      "Role": "User",
      "id": 15,
      "userName": "huadong",
      "userNickName": "huadong"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Mon, 26 Aug 2024 10:40:43 GMT",
      "Role": "User",
      "id": 16,
      "userName": "tengyunshengshi",
      "userNickName": "tengyunshengshi"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Mon, 26 Aug 2024 18:17:21 GMT",
      "Role": "User",
      "id": 17,
      "userName": "Media1",
      "userNickName": "Media1"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Mon, 26 Aug 2024 18:17:30 GMT",
      "Role": "User",
      "id": 18,
      "userName": "Media2",
      "userNickName": "Media2"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Mon, 26 Aug 2024 18:17:36 GMT",
      "Role": "User",
      "id": 19,
      "userName": "Media3",
      "userNickName": "Media3"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Wed, 28 Aug 2024 18:34:32 GMT",
      "Role": "User",
      "id": 20,
      "userName": "Anker1",
      "userNickName": "Anker1"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Mon, 02 Sep 2024 10:40:36 GMT",
      "Role": "User",
      "id": 21,
      "userName": "yeren",
      "userNickName": "yeren"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Tue, 03 Sep 2024 09:55:09 GMT",
      "Role": "User",
      "id": 22,
      "userName": "DataStory001",
      "userNickName": "DataStory001"
    },
    {
      "ActiveStatus": true,
      "CreatedDate": "Tue, 03 Sep 2024 09:55:18 GMT",
      "Role": "User",
      "id": 23,
      "userName": "DataStory002",
      "userNickName": "DataStory002"
    }
  ]