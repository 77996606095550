import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.css'

export const RangeSliderWithCustomDisplay = ({
    value,
    setValue,
    max,
    min,
    maxDisplay,
    minDisplay,
    step,
    disabled,
    postFix
}) => {
    const valueToDisplay = value * (maxDisplay - minDisplay) / (max - min) + minDisplay;
    return (
        <div className='slider-wrapper'>
            <div className='current-value' style={{
                paddingLeft: `${value/max*100 - 3}%`
            }}>
                <span>{valueToDisplay}{postFix}</span>
            </div>
        <Slider
            className=''
            value={value}
            onChange={!disabled ? setValue: null}
            min={0}
            max={100}
            trackStyle={{ backgroundColor: '#215EF9'}}
            handleStyle={{ backgroundColor: '#215EF9'}}
            railStyle={{ backgroundColor: '#A3A3A3' }}
            step={step}
            // onChangeComplete={this.onAfterChange}
          />
        <div className='min-max-value'>
            <span>{minDisplay}{postFix}</span>
            <span>{maxDisplay}{postFix}</span>
        </div>
        </div>
    )
}