import React, { useState, useEffect } from "react";
import './style.css';
import { getCurrentDomain } from "../utils";
import closeIcon from '../images/close_button.svg';
import BeatLoaderSpinner from "./LoadingPage/BeatLoaderSpinner";
import axios from 'axios';
import errorIcon from '../images/error_icon.svg';
import { MOCK_USERS } from "./mockUser";
import Pagination from 'react-bootstrap/Pagination';

const NUMBER_OF_ITEMS_PER_PAGE = 15;

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newUserName, setNewUserName] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [newUserPasswordConfirm, setNewUserPasswordConfirm] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDuplicateAccountModal, setShowDuplicateAccountModal] = useState(false);
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [newChangePassword, setNewChangePassword] = useState('');
    const [newChangePasswordConfirm, setNewChangePasswordConfirm] = useState('');
    const [showModalToDisableUser, setShowModalToDisableUser] = useState(false);        
    const [currentPage, setCurrentPage] = useState(1);          


    useEffect(() => {

        const getCurrentUsers = async () => {
            try {
                setLoading(true);
                const url = `${getCurrentDomain()}all-users`;
                const response = await fetch(url);
                const data = await response.json();
                
                setUsersSorted([...data.users]);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setUsersSorted([...MOCK_USERS])
            }

        }
        getCurrentUsers();
    }, []);

    const setUsersSorted = (users) => {
        users.sort((a, b) => {
            return new Date(b.CreatedDate) - new Date(a.CreatedDate);
        });
        setUsers(users);
    }

    const renderColWidth = () => {
        return (
            <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
            </colgroup>
        )
    };

    const renderButton = (text, onClick) => {
        return (
            <div className="user-management-action-button" onClick={onClick}>
                {text}
            </div>
        )
    }

    const getUserToDisplay = () => {
        return users.slice((currentPage - 1) * NUMBER_OF_ITEMS_PER_PAGE, currentPage * NUMBER_OF_ITEMS_PER_PAGE);
    }

    const renderUsersTable = () => {
        return (
            <table className="user-management-table">
                {renderColWidth()}
                <thead>
                    <tr>
                        <th>
                            <div className="table-cell-center">
                                账号
                            </div>
                        </th>
                        <th>
                            <div className="table-cell-center">
                                昵称
                            </div>
                        </th>

                        <th>
                            <div className="table-cell-center">
                                注册时间
                            </div>
                        </th>
                        <th>
                            <div className="table-cell-center">
                                账号状态
                            </div>
                        </th>
                        <th>
                            <div className="table-cell-center">
                                账号操作
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {getUserToDisplay().map((user, index) => {
                        const className = index % 2 === 0 ? 'row-even' : 'row-odd';
                        return (
                            <tr key={index} className={`user-row ${className}`}>
                                <td>
                                    <div className="table-cell-center">
                                        {user.userName}
                                    </div>
                                </td>
                                <td>
                                    <div className="table-cell-center">
                                        {user.userNickName}
                                    </div>
                                </td>
                                <td>
                                    <div className="table-cell-center">
                                        {convertDateToString(user.CreatedDate)}
                                    </div>
                                </td>
                                <td>
                                    <div className="table-cell-center">
                                        {user.ActiveStatus ? '启用' : '已注销'}
                                    </div>
                                </td>
                                <td>
                                    <div className="user-management-table-operation">
                                        {renderButton('查看素材中心', () => openUserLibraryInNewTab(user.userName))}
                                        {user.ActiveStatus  && renderButton('重置密码', () => showPopupChangePassword(user))}
                                        {user.ActiveStatus  && renderButton('账号注销', () => popupDisableUser(user))}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }
    const openUserLibraryInNewTab = (userName) => {
        const url = `${getCurrentDomain()}library/${userName}`;
        window.open(url, '_blank');
    }

    const popupDisableUser = (user) => {
        setSelectedUser(user);
        setShowModalToDisableUser(true);
    }

    const showPopupChangePassword = (user) => {
        setSelectedUser(user);
        setShowModalChangePassword(true);
    }

    const paddingToTwoDigits = (number) => {
        return number < 10 ? `0${number}` : number;
    }

    const convertDateToString = (dateStringFull) => {
        const date = new Date(dateStringFull);
        //example: 2024年02月04日
        return `${date.getFullYear()}年${paddingToTwoDigits(date.getMonth() + 1)}月${paddingToTwoDigits(date.getDate())}日`;
    }


    const renderButonAddNewUser = () => {
        return (
            <div className="user-management-add-new-user" onClick={() => setShowModal(true)}>
                新建账号
            </div>
        )
    }

    const renderModalContentShowAccountExist = () => {
        return (
            <div className="user-management-modal">
                <div className="duplicate-account-wrapper">
                    <div style={{
                        marginTop: 150,
                        fontSize: 24,
                        fontWeight: 700,
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 94
                    }}>
                        该账号已存在，请重新输入账号名称
                    </div>
                    <div className="user-management-modal-footer" style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div className="user-management-modal-button" onClick={closeModalDuplicateAccount} style={{
                            marginLeft: 0
                        }}>
                            确定
                        </div>
                    </div>
                    {renderButtonCloseModal(closeModalDuplicateAccount)}
                </div>

            </div>

        )
    }

    const closeModalDuplicateAccount = () => {
        setShowDuplicateAccountModal(false);
    }

    const closeRegisterModal = () => {
        setShowModal(false);
    }

    const renderModelContentToCreateNewUser = () => {
        return (
            <div className="user-management-modal-content">
                <div className="user-management-modal-header">
                    新建平台账号
                </div>
                <div className="user-management-modal-body">
                    <div className="user-management-modal-input">
                        <div className="user-management-modal-input-label">
                            账号
                        </div>
                        <input className="user-management-modal-input-field"
                            placeholder=""
                            value={newUserName}
                            onChange={(e) => setNewUserName(e.target.value)}
                        />
                    </div>
                    <div className="user-management-modal-input">
                        <div className="user-management-modal-input-label">
                            初始密码
                        </div>
                        <input className="user-management-modal-input-field"
                            placeholder="至少6位新密码"
                            value={newUserPassword}
                            onChange={(e) => setNewUserPassword(e.target.value)}
                        />
                    </div>
                    <div className="user-management-modal-input" style={{
                            position: 'relative'
                        }}>
                        <div className="user-management-modal-input-label">
                            确认初始密码
                        </div>
                        <input
                            className="user-management-modal-input-field"
                            value={newUserPasswordConfirm}
                            onChange={(e) => setNewUserPasswordConfirm(e.target.value)}
                            placeholder="至少6位新密码"
                        />
                
                        {newUserPassword.length > 0 &&  newUserPassword.length < 6  ? renderErrorMessage("请输入至少6位新密码") : (newUserPassword != newUserPasswordConfirm) ? renderErrorMessage("两次输入的密码不一致"): null}
                    </div>
                </div>
                <div className="user-management-modal-footer">
                    <div className="user-management-modal-button" onClick={createNewUser}>
                        确定
                    </div>
                </div>
                {renderButtonCloseModal(closeModal)}
            </div>
        )
    }

    const renderModalToCreateNewUser = () => {
        return (
            <div className="user-management-modal">
                {renderModelContentToCreateNewUser()}
            </div>
        )
    }


    const renderModalToChangeUserPassword = () => {
        return (
            <div className="user-management-modal">
                <div className="user-management-modal-content">
                    <div className="user-management-modal-header" style={{
                        marginBottom: 10
                    }}>
                        请为该账号重置密码
                    </div>
                    <div className="user-management-modal-body">
                        <div className="user-management-modal-input" style={{
                            marginBottom: 10
                        }}>
                            <div className="user-management-modal-input-label">
                                账号
                            </div>
                            <div>
                                {selectedUser.userName}
                            </div>
                        </div>
                        <div className="user-management-modal-input" style={{
                            marginBottom: 10
                        }}>
                            <div className="user-management-modal-input-label">
                                注册时间
                            </div>
                            <div style={{
                                color: '#696969'
                            }}>
                                {convertDateToString(selectedUser.CreatedDate)}
                            </div>
                        </div>
                        <div className="user-management-modal-input" style={{
                            marginBottom: 10
                        }}>
                            <div className="user-management-modal-input-label">
                                账号状态
                            </div>
                            <div style={{
                                color: '#696969'
                            }}>
                               {selectedUser.ActiveStatus ? '启用' : '已注销'}
                            </div>
                        </div>
                        <div className="user-management-modal-input">
                            <div className="user-management-modal-input-label">
                                初始密码
                            </div>
                            <input className="user-management-modal-input-field"
                                placeholder="至少6位新密码"
                                value={newChangePassword}
                                onChange={(e) => setNewChangePassword(e.target.value)}
                            />
                        </div>
                        <div className="user-management-modal-input" style={{
                            position: 'relative'
                        }}>
                            <div className="user-management-modal-input-label">
                                确认初始密码
                            </div>
                            <input
                                className="user-management-modal-input-field"
                                value={newChangePasswordConfirm}
                                onChange={(e) => setNewChangePasswordConfirm(e.target.value)}
                                placeholder="至少6位新密码"
                            />
                            {newChangePassword.length > 0 &&  newChangePassword.length < 6  ? renderErrorMessage("请输入至少6位新密码") : (newChangePasswordConfirm != newChangePassword) ? renderErrorMessage("两次输入的密码不一致"): null}
                        </div>
                    </div>
                    <div className="user-management-modal-footer" style={{
                        marginTop: 40
                    }}>
                        <div className="user-management-modal-button" onClick={changeUserPassword}>
                            确定
                        </div>
                    </div>
                    {renderButtonCloseModal(closeModalChangePassword)}
                </div>
            </div>
        )
    }


  const renderErrorMessage = (message) => {
    return (
      <div className='error-message-below-input'>
        <img src={errorIcon}  width={16} className='error-icon' />
        <div className='error-message' style={{
          marginLeft: 10
        }}>
          {message}
        </div>
      </div>
    )
  }

    const closeModalChangePassword = () => {
        setShowModalChangePassword(false);
    }

    const changeUserPassword = async () => {
        if (newChangePassword !== newChangePasswordConfirm || newChangePassword.length < 6) {
            //show error message
            return;
        }
        setLoading(true);
        const url = `${getCurrentDomain()}/admin-change-password`;
        const data = {
            userName: selectedUser.userName,
            newPassword: newChangePassword,
        }
        try {
            const response = await axios.post(url, data);

            if (response.status === 200) {
                setShowModalChangePassword(false);
                setNewChangePassword('');
                setNewChangePasswordConfirm('');
            }
                

        } catch (error) {
            //show create user error
            
        }
        finally {
            setLoading(false);
        }
    }



    const createNewUser = async () => {
        if (newUserPassword !== newUserPasswordConfirm || newUserPassword.length < 6 || newUserName.length <= 0) {
            //show error message
            return;
        }
        setLoading(true);
        const url = `${getCurrentDomain()}/register`;
        const data = {
            userName: newUserName,
            password: newUserPassword,
            userNickName: newUserName,
        }
        try {
            const response = await axios.post(url, data);

            if (response.status === 200) {
                setShowModal(false);
                setNewUserName('');
                setNewUserPassword('');
                setNewUserPasswordConfirm('');

                //add new user to the list
                const newUser = {
                    userName: newUserName,
                    userNickName: newUserName,
                    CreatedDate: (new Date()).toDateString(),
                    ActiveStatus: true,
                }
                setUsers([newUser, ...users]);
                closeModal();
            }

        } catch (error) {
            //show create user error
            setShowDuplicateAccountModal(true);
        }
        finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const renderButtonCloseModal = (close) => {
        return (
            <div className="user-management-modal-close" onClick={close}>
                <img className="user-management-modal-close-icon" src={closeIcon} />
            </div>
        )
    }

    const renderLoadingPage = () => {
        return (
            <div className='col-12' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                display: loading ? 'flex' : 'none',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 10000000000000
            }}>
                <BeatLoaderSpinner />
            </div>
        )
    }

    const renderModalToDisableUser = () => {
        return (
            <div className="user-management-modal">
                <div className="user-management-modal-content" style={{
                    padding: 0
                }}>
                    <div className="user-management-modal-header" style={{
                        marginTop: 102,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        注销后该账号将无法再登录该平台
                    </div>
                    <div className="user-management-modal-header" style={{
                        marginTop: 17,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        确定注销该账号吗？
                    </div>
                    
                    <div className="user-management-modal-footer" style={{
                        marginTop: 86,
                        display: 'flex',
                        justifyContent: 'center'


                    }}>
                        <div className="user-management-modal-button" onClick={disableUser} style={{
                            display: 'flex',
                            marginLeft: 0
                        }}>
                            确定
                        </div>
                    </div>
                    {renderButtonCloseModal(closeModalToDisableUser)}
                </div>
            </div>
        )
    }

    const disableUser = async () => {
        setLoading(true);
        const url = `${getCurrentDomain()}/admin-disable-user`;
        const data = {
            userName: selectedUser.userName,
        }
        try {
            const response = await axios.post(url, data);

            if (response.status === 200) {
                setShowModalToDisableUser(false);
                const updatedUsers = users.map((user) => {
                    if (user.userName === selectedUser.userName) {
                        return {
                            ...user,
                            ActiveStatus: false,
                        }
                    }
                    return user;
                })
                setUsers(updatedUsers);

            }
        } catch (error) {
            //show create user error
        }
        finally {
            setShowModalToDisableUser(false);
            setLoading(false);
        }
    }

    const closeModalToDisableUser = () => {
        setShowModalToDisableUser(false);
    }

    const renderPagination = () => {
        const numberOfPages = Math.ceil(users.length / NUMBER_OF_ITEMS_PER_PAGE);
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20
            }}>
                <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}  />
                    {
                        Array.from({ length: numberOfPages }).map((_, index) => {
                            return (
                                <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => setCurrentPage(index + 1)}>
                                    {index + 1}
                                </Pagination.Item>
                            )
                        })
                    }
                    <Pagination.Next onClick={() => setCurrentPage(currentPage < numberOfPages ? currentPage + 1: currentPage)}  />
                </Pagination>
            </div>
        )
    }


    return (
        <div className="user-management-wrapper">
            <div className="user-management-header">
                <div>
                    账号列表
                </div>
                {renderButonAddNewUser()}
            </div>
            {renderUsersTable()}
            {renderPagination()}
            {showModal && renderModalToCreateNewUser()}
            {showDuplicateAccountModal && renderModalContentShowAccountExist()}
            {showModalChangePassword && renderModalToChangeUserPassword()}
            {showModalToDisableUser && renderModalToDisableUser()}
            {renderLoadingPage()}

        </div>
    )

}

export default UserManagement;