import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './styles.css';
import prevArrow from '../../images/left.svg';
import nextArrow from '../../images/right.svg';
import default_thumbnail from '../../images/default_thumbnail.png';

const renderImageInSlide = (image, onImageSelect, isSelected) => {
    return (
        <div className='each-slide' onClick={() => onImageSelect(image)}>
            <img src={image} alt={image} style={{
                border: isSelected ? '2px solid #fff' : 'none'
            }} 
            onError={(e) => {
                e.currentTarget.src = default_thumbnail;
                return default_thumbnail;
            }}
            />
        </div>
    )
}

const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px',
    marginRight: '10px'
};

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.7783 13.1409C5.77773 11.9866 6.21011 10.9763 7.07545 10.1101L16.3062 1.88066C16.8831 1.30319 17.749 1.44705 18.3264 2.02395C18.9039 2.60084 18.76 3.46668 18.1831 4.04415L8.95243 12.4179C8.80821 12.5622 8.66406 12.8509 8.6642 13.1395C8.66434 13.4281 8.80878 13.7166 9.09752 14.005L18.3363 21.9366C18.9138 22.5135 19.0585 23.3792 18.4816 23.9567C17.9048 24.5342 17.0391 24.6789 16.4616 24.102L7.22276 16.1704C6.21234 15.4494 5.77895 14.4396 5.7783 13.1409Z" fill="#969696"/>
    </svg>
    
    </button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.2077 13.1409C20.2082 11.9866 19.7759 10.9763 18.9105 10.1101L9.67974 1.88066C9.10285 1.30319 8.23701 1.44705 7.65954 2.02395C7.08208 2.60084 7.22594 3.46668 7.80283 4.04415L17.0335 12.4179C17.1778 12.5622 17.3219 12.8509 17.3218 13.1395C17.3216 13.4281 17.1772 13.7166 16.8884 14.005L7.64962 21.9366C7.07215 22.5135 6.92742 23.3792 7.50431 23.9567C8.08121 24.5342 8.9469 24.6789 9.52437 24.102L18.7632 16.1704C19.7736 15.4494 20.207 14.4396 20.2077 13.1409Z" fill="#969696"/>
    </svg>
    </button>,
    indicators: false,
    autoplay: false,
    infinite: false,
}


const ImagesSlider = ({images, onSelectImage, selectedImage}) => {
    return (
        <div style={{ maxWidth: '60vw', overflow: 'hidden' }}>

        <Slide {...properties} slidesToShow={images.length < 4 ? images.length : 4} slidesToScroll={2}>
            {
                images.map((image) => renderImageInSlide(image, onSelectImage, selectedImage === image))
            }
        </Slide>
        </div>
    );
};

export default ImagesSlider;