import React from 'react';

const InlineInputWithTitle = ({ title, value, onChange, min, max, numberType }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        if (numberType === 'integer') {
            if (value === '') {
                onChange('');
                return;
            }
            if (isNaN(value)) {
                onChange('');
                return;
            }
    

            //auto parse the value to integer
            onChange(parseInt(value));
            return;
        }
        //verify if the value is a number and if it is between the min and max and auto adjust the value to the min or max
        if (isNaN(value)) {
            onChange('');
            return;
        }

        if (value === '') {
            onChange('');
            return;
        }

        if (value < min) {
            onChange('');
            return;
        }
        else if (value > max) {
            onChange(max);
            return;
        }
        else {
            onChange(Number(value));
        }


    }
    return (
        <div className='col-12'>
            <div className='input-input-with-title-wrapper'>  
                <label>{title}</label>
                <input 
                    value={value} 
                    onChange={handleChange}
                    type='number'
                    min={min}
                    max={max}
                />
            </div>
        </div>
        
    );
};

export default InlineInputWithTitle;
