import { LOADING_ICON_COLOR } from './constants';
import { BeatLoader } from 'react-spinners';
import './styles.css';
export const BEAT_LOADER_CLASS = 'beat-loader';

const BeatLoaderSpinner = () => {
    return (
        <div className={BEAT_LOADER_CLASS}>
            <BeatLoader size={'1rem'} color={LOADING_ICON_COLOR} loading />
        </div>
    );
};
export default BeatLoaderSpinner;
