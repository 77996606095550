import logo from './logo.svg';
import './App.css';
import ImagesGenerators from './pages/ImagesGenerators';
import Header from './components/Header';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import UploadModels from './pages/UploadModels';
import LoginForm from './pages/Login';
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ImagesGenerators />,
    },
    {
      path: "/home",
      element: <ImagesGenerators />,
    },
    {
      path: "/user_management",
      element: <ImagesGenerators isUserManagement={true} activeTabInit={null}/>,
    },
    {
      path: "/library",
      element: <ImagesGenerators isLibrary={true} activeTabInit={null}/>,
    },
    {
      path: "/library/:userName",
      element: <ImagesGenerators isLibrary={true} isAdminAccessUserPage={true} activeTabInit={null} />,
    },
    {
      path: "/login",
      element: <LoginForm />,
    }
  ]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <RouterProvider router={router} />
    </div>
    
  );
}

export default App;
