import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import pen_icon from '../images/pen_icon.svg';
import earse_icon from '../images/earse_icon.svg';
import change_pen_earse_size_icon from '../images/change_pen_earse_size_icon.svg';
import undo_icon from '../images/undo_icon.svg';
import redo_icon from '../images/redo_icon.svg';
import remove_background_icon from '../images/remove_background_icon.svg';
import pen_icon_selected from '../images/pen_icon_selected.svg';
import earse_icon_selected from '../images/earse_icon_selected.svg';
import change_pen_earse_size_icon_selected from '../images/change_pen_earse_size_icon_selected.svg';
import undo_icon_selected from '../images/undo_icon_selected.svg';
import redo_icon_selected from '../images/redo_icon_selected.svg';
import sample_icon from '../images/sample_icon.svg';

import remove_background_icon_selected from '../images/remove_background_icon_selected.svg';
import { APP_ACTIVE_COLOR } from '../constant';
import { RangeSliderWithCustomDisplay } from '../components/RangeSliderWithCustomDisplay/RangeSliderWithCustomDisplay';
import { getImagePath } from '../utils';
import './custom_canvas_style.css';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const EDIT_FEATURE_PEN = 'pen';
const EDIT_FEATURE_ERASE = 'erase';
const EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE = 'change_pen_erase_size';
const EDIT_FEATURE_UNDO = 'undo';
const EDIT_FEATURE_REDO = 'redo';
const EDIT_FEATURE_REMOVE_BACKGROUND = 'remove_background';
const EDIT_AUTO_SAM = 'auto-sam';
const PAINT_COLOR = 'rgba(255, 0, 0, 0.5)';



const ImageEditingTool = forwardRef(({ 
    imageUrl, 
    handleAutoSam, 
    handleRemoveBackground,
    isLoading, allImagesOfUser, imagePathOnly, changeImageToDraw,
    edittingMaskInBase64,
    closeImageEditingTool, saveMask,
    editingImageMasksAutoSam,
    shouldDisplayMaskToSelect
}, ref) => {
  const canvasRef = useRef(null);
  const highlightCanvasRef = useRef(null);
  const [brushSize, setBrushSize] = useState(10);
  const [selectedButtonToEditImage, setSelectedButtonToEditImage] = useState(null);
  const CANVAS_HEIGHT = 700;
  const redoHistoryRef = useRef([]);
  const historyRef = useRef([]);

  

  const [isDrawing, setIsDrawing] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [context, setContext] = useState(null);
  const [highlightContext, setHighlightContext] = useState(null);
  const [mode, setMode] = useState('drawing');
  const lastXRef = useRef(0);
  const lastYRef = useRef(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const mousePositionRef = useRef(mousePosition);
  const isHoveringRef = useRef(isHovering);
  const selectedMaskPartsListRef = useRef([]);

  const [highlightCanvasRefBasedOnPart, setHighlightCanvasRefBasedOnPart] = useState(null);
  const highlightCanvasRefBasedOnPartRef = useRef(highlightCanvasRefBasedOnPart);

  useEffect(() => {
    highlightCanvasRefBasedOnPartRef.current = highlightCanvasRefBasedOnPart;
  }, [highlightCanvasRefBasedOnPart]);

  useEffect(() => {
    mousePositionRef.current = mousePosition;
  }, [mousePosition]);
  useEffect(() => {
    isHoveringRef.current = isHovering;
  }, [isHovering]);

  const getMarkedCanvas = () => {
    return new Promise((resolve, reject) => {
      if (canvasRef.current) {
        //return the current canvas, don't need to convert to image
        resolve(canvasRef.current);
      }
    })
  }

  const renderChangePenEarseSizeModal = () => {
    const max = 100;
    const min = 0;
    const maxDisplay = 100;
    const minDisplay = 0;
    const step = 1;
    const disabled = false;
    const postFix = "%";
    
    const valueToDisplay = brushSize * (maxDisplay - minDisplay) / (max - min) + minDisplay;
    return (
      <div className='col-12' style={{
        position: 'absolute',
        top: 15,
        width: 200,
        left: -50,
        zIndex: 100,
      }}>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
        {/* <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'> */}


<div className='slider-wrapper' style={{
    color: '#696969',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '200px',
    position: 'relative',
}}>
    <div className='current-value' style={{
        fontSize: '12px',
        position: 'absolute',
        bottom: `calc(${Math.min(Math.max((brushSize / max) * 100, 0), 95)}%)`,
        left: 'calc(100% - 25px)',
        transform: 'translateX(-90%)'
    }}>
        <span>{valueToDisplay}{postFix}</span>
    </div>

    <div className='min-max-value' style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '12px',
        position: 'absolute',
        top: 0,
        height: '100%',
        justifyContent: 'space-between',
        paddingLeft: '45px',
    }}>
        <span>{maxDisplay}{postFix}</span>
        <span>{minDisplay}{postFix}</span>
    </div>

    <Slider
        value={brushSize}
        onChange={!disabled ? setBrushSize : null}
        min={0}
        max={100}
        trackStyle={{ backgroundColor: '#215EF9', width: '6px' }}
        handleStyle={{ backgroundColor: '#215EF9', width: '12px', height: '12px' }}
        railStyle={{ backgroundColor: '#A3A3A3', width: '6px' }}
        step={step}
        vertical
    />
{/* </div> */}
</div>
          {/* <RangeSliderWithCustomDisplay
            value={brushSize}
            setValue={setBrushSize}
            max={100}
            min={0}
            maxDisplay={100}
            minDisplay={0}
            step={1}
            disabled={false}
            postFix={"%"}
          /> */}
        </div>

      </div>
    )
  }
  const renderToolBarForEditingImage = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: 41,
        justifyContent: 'flex-end',
        position: 'absolute',
        right: -41,
        bottom: 0,

      }}>
          {
            renderButtonWithIcon(
              EDIT_FEATURE_PEN,
              pen_icon,
              pen_icon_selected,
              '画笔',
              () =>{
                setDrawingMode()
              } ,
            )
          }
          {
            renderButtonWithIcon(
              EDIT_FEATURE_ERASE,
              earse_icon,
              earse_icon_selected,
              '橡皮擦',
              () => {

                setEraserMode()
              },
            )
          }
          <div style={{
            position: 'relative',
          }}>
            {
              renderButtonWithIcon(
                EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE,
                change_pen_earse_size_icon,
                change_pen_earse_size_icon_selected,
                '笔触大小',
                () => setSelectedButtonToEditImage(EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE),
              )
            }
            {
              selectedButtonToEditImage == EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE && renderChangePenEarseSizeModal()
            }
          </div>


          {
            renderButtonWithIcon(
              EDIT_FEATURE_UNDO,
              undo_icon,
              undo_icon_selected,
              '撤销',
              () => undo(),
            )
          }
          {renderButtonWithIcon(
            EDIT_FEATURE_REDO,
            redo_icon,
            redo_icon_selected,
            '重做',
            () => redo(),
            false
          )}

        {
          renderButtonWithIcon(
            EDIT_FEATURE_REMOVE_BACKGROUND,
            remove_background_icon,
            remove_background_icon_selected,
            '去除背景',
            () => { removeBackgroundButtonClick() },
            false,
          )
        }
        {
          renderButtonWithIcon(
            EDIT_AUTO_SAM,
            sample_icon,
            sample_icon,
            '去除背景', 
            () => { autoSamButtonClick() },
            false,
            {
              marginTop: 10,
            }
          )
        }
        </div>
    )
  }

  const removeBackgroundButtonClick = () => {
    setSelectedButtonToEditImage(EDIT_FEATURE_REMOVE_BACKGROUND)
    handleRemoveBackground();
  }

  const autoSamButtonClick = () => {
    setSelectedButtonToEditImage(EDIT_AUTO_SAM)
    //Edit
    setDrawingMode()
    handleAutoSam();
   
    
  }



  const renderButtonWithIcon = (feature, icon, selected_icon, text, onClick, shouldDrawBorder = true, customStyle = {}) => {
    const isSelected = selectedButtonToEditImage === feature;
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: isSelected ?  'white' : 'black',
        cursor: 'pointer',
        fontSize: 14,
        backgroundColor: isSelected ? APP_ACTIVE_COLOR : 'white',
        width: 41,
        height: 41,
        justifyItems: 'center',
        marginTop: 2,
        marginLeft: 1,
        ...customStyle

      }}
        onClick={onClick}
      >
          <img src={isSelected ? selected_icon : icon} style={{
            width: 18,
            height: 18,
          }} />
        
      </div>
    )
  }



  // Expose the getMarkedCanvas method to parent
  useImperativeHandle(ref, () => ({
    getMarkedCanvas,
  }));

  const setDrawingMode = () => {
    setSelectedButtonToEditImage(EDIT_FEATURE_PEN);
    toggleDrawingMode();
  }

  const setEraserMode = () => {
    setSelectedButtonToEditImage(EDIT_FEATURE_ERASE);
    toggleBrushingMode();
  }

  const renderLoadingBackground = () => {
    return (
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(7, 7, 7, 0.7)',
        top: 0,
        left: 0,
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'white',
      }}>
        正在识别遮罩
      </div>
    )
  }



  useEffect(() => {
    //reset history when image url changes
    
    historyRef.current = [];
    redoHistoryRef.current = [];
    selectedMaskPartsListRef.current = []

    if (context) {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    if (highlightContext) {
      highlightContext.clearRect(0, 0, highlightCanvasRef.current.width, highlightCanvasRef.current.height);
    }

    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      setCanvasWidth(CANVAS_HEIGHT * aspectRatio); // Set canvas height based on the image aspect ratio

      if (edittingMaskInBase64 != '') {
        console.log('maskInDB', edittingMaskInBase64)
        //load the image mask and set to canvas
        const maskImage = new Image();
        maskImage.crossOrigin = 'anonymous'; // Enable CORS
        maskImage.src = edittingMaskInBase64;
        maskImage.onload = () => {
          if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            //convert maskImage to data and set to canvas

            ctx.drawImage(maskImage, 0, 0, canvasRef.current.width, canvasRef.current.height);
          }
        }
      }



    };
  }, [imageUrl]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      setContext(ctx);
    }

    if (highlightCanvasRef.current) {
      const ctx = highlightCanvasRef.current.getContext('2d');
      setHighlightContext(ctx);
    }
  }, [canvasWidth]);

  const startDrawing = (event) => {
    if (!context) return;


    //clear the current circle to 

    setIsDrawing(true);
    lastXRef.current = event.nativeEvent.offsetX;
    lastYRef.current = event.nativeEvent.offsetY;
    drawMask(event);
  };

  const drawMask = (event) => {
    if (!context) return;
    
    context.strokeStyle = PAINT_COLOR;
    context.lineWidth = brushSize;
    context.lineCap = 'round'; // Makes the ends of the line rounded
    const newX = event.nativeEvent.offsetX;
    const newY = event.nativeEvent.offsetY;

    context.globalCompositeOperation = 'destination-out';

    // Draw a line connecting the last position and the current position
    context.beginPath();
    context.moveTo(lastXRef.current, lastYRef.current);
    context.lineTo(newX, newY);
    context.stroke();
    context.closePath();



    if (mode === 'drawing' ) {
      context.globalCompositeOperation = 'source-over';
      context.strokeStyle = PAINT_COLOR;
      context.lineCap = 'round'; // Makes the ends of the line rounded

      // Draw a line connecting the last position and the current position
      context.beginPath();
      context.moveTo(lastXRef.current, lastYRef.current);
      context.lineTo(newX, newY);
      context.stroke();
      context.closePath();
  
    }
    lastXRef.current = newX;
    lastYRef.current = newY;

  };

  const draw = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setMousePosition({
      x: x,
      y: y
    });
    if (!isDrawing) return;
    drawMask(event);
    
    

  };

  const highlightMask = (event) => {
    const rect = highlightCanvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setMousePosition({
      x: x,
      y: y
    });
    if (editingImageMasksAutoSam) {
      console.log(x, y)
      highlightCanvasRefBasedOnMousePosition(x, y);
    }

  };

  const highlightCanvasRefBasedOnMousePosition = (x, y) => {
    const coorX = Math.floor(x);
    const coorY = Math.floor(y);
    try {
      const part = editingImageMasksAutoSam[coorY][coorX];

      if (highlightCanvasRefBasedOnPartRef.current != part) {
        setHighlightCanvasRefBasedOnPart(part);
        const partsToHighlight = [...selectedMaskPartsListRef.current, part];
  
        highlightContext.clearRect(0, 0, highlightCanvasRef.current.width, highlightCanvasRef.current.height);
        for (let i = 0; i < editingImageMasksAutoSam.length; i++) {
          for (let j = 0; j < editingImageMasksAutoSam[0].length; j++) {
            if (partsToHighlight.includes(editingImageMasksAutoSam[i][j])) {
              highlightContext.fillStyle = 'rgba(255, 0, 0, 1)';
              highlightContext.fillRect(j, i, 1, 1);
            }
          }
        }
      }
    }
    catch (error) {
      console.log('error', error)
    }

  }

  const endDrawing = () => {
    if (context) {
      context.closePath();
    }
    if (isDrawing) {
      addTheCurrentCanvasToHistory();
    }
    setIsDrawing(false);
    context.globalCompositeOperation = 'source-over';
  };

  const endDrawingAndSetNoHovering = () => {
    endDrawing();
    setIsHovering(false);
  }


  const addTheCurrentCanvasToHistory = () => {
    const newCanvas = canvasRef.current.toDataURL();
    historyRef.current.push(newCanvas);
    redoHistoryRef.current = [];
  };

  const undo = () => {
    if (historyRef.current.length === 0) return;
    const lastState = historyRef.current.pop();

    redoHistoryRef.current.push(lastState);

    if (historyRef.current.length > 0) {
      const previousState = historyRef.current[historyRef.current.length - 1];
      restoreCanvas(previousState);
    } else {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas if no history
    }
  };

  const redo = () => {
    if (redoHistoryRef.current.length === 0) return;
    const redoState = redoHistoryRef.current.pop();
    historyRef.current.push(redoState);
    restoreCanvas(redoState);
  };

  const restoreCanvas = (state) => {
    const img = new Image();
    img.src = state;
    img.onload = () => {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.drawImage(img, 0, 0);
    };
  };

  const toggleDrawingMode = () => {
    setMode('drawing');
  };

  const toggleBrushingMode = () => {
    setMode('brushing');
  };

  const renderOriginalImageAndMaskInSmallView = (image) => {
    return (
      <div style={{
        position: 'absolute',
        width: 150,
        backgroundImage: `url(${getImagePath(image.originalImagePath)})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        left: -170,
        bottom: 0,
        cursor: 'pointer',
      }}
      onClick={() => {changeImageToDraw(image)}}
      >
        <img src={getImagePath(image.originMarkPath)} style={{
          width: 150,
          left: 0,
          bottom: 0,
        }} />
      </div>
    )
  }


  const renderRawImageAndMaskIfNeed = () => {
    const image = allImagesOfUser.find((image) => image.imagePath === imagePathOnly);
    if (image && image.originMarkPath && image.originalImagePath) {
      return renderOriginalImageAndMaskInSmallView(image)
    }
    return (
      <></>
    )
  }

  const renderTopToolBar = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        position: 'absolute',
        top: -60,
        color:'white',
        alignItems: 'center',

      }}>
        <div>
          请选择编辑的区域
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          
        }}>
          {
            renderButton(
              '确定',
              'white',
              APP_ACTIVE_COLOR,
              () => { 
                saveMask();
              },
            )
          }
          {
            renderButton(
              '取消',
              'black',
              'white',
              () => { 
                closeImageEditingTool();
              },
            )
          }
        </div>
      </div>
    )
  }

  const togleSelectedMaskPart = () => {
    const coorX = Math.floor(mousePosition.x);
    const coorY = Math.floor(mousePosition.y);
    const part = editingImageMasksAutoSam[coorY][coorX];
    if (selectedMaskPartsListRef.current.includes(part)) {
      selectedMaskPartsListRef.current = selectedMaskPartsListRef.current.filter((item) => item !== part);
    } else {
      selectedMaskPartsListRef.current.push(part);
    }

    //also update on the canvas
    if (context) {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      for (let i = 0; i < editingImageMasksAutoSam.length; i++) {
        for (let j = 0; j < editingImageMasksAutoSam[0].length; j++) {
          if (selectedMaskPartsListRef.current.includes(editingImageMasksAutoSam[i][j])) {
            context.fillStyle = PAINT_COLOR;
            context.fillRect(j, i, 1, 1);
          }
        }
      }
  
    }
    

  }

  const renderButton = (text, color, backgroundColor, onClick) => {
    return (
      <div
        style={{
          width: 156,
          height: 49,
          marginLeft: 24,
          color,
          backgroundColor,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
      >
        {text}
      </div>
    )
  }

  return (
    <div style={{
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 10000,
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',  
      alignItems  : 'center',
      justifyContent: 'center',

    }}>
      {
        <div
          style={{
            position: 'relative',
            backgroundImage: `url(${imageUrl})`,
            height: CANVAS_HEIGHT,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        >
          
          <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={CANVAS_HEIGHT}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={endDrawing}
            onMouseLeave={endDrawingAndSetNoHovering}
            onMouseEnter={() => { setIsHovering(true) }}
            className="custom-canvas"
          />
          { 
          <canvas
            style={{
              position: 'absolute',
              left: 0,
              display: shouldDisplayMaskToSelect ? '' : 'none',
            }}
            ref={highlightCanvasRef}
            width={canvasWidth}
            height={CANVAS_HEIGHT}
            onMouseMove={highlightMask}
            onMouseDown={togleSelectedMaskPart}
            onMouseEnter={() => { setIsHovering(true) }}
          />
          } 
          
          {isHovering && (
        <div
          className="custom-cursor"
          style={{
            left: `${mousePosition.x - brushSize / 2}px`,
            top: `${mousePosition.y - brushSize / 2}px`,
            width: `${brushSize}px`,
            height: `${brushSize}px`,
            zIndex: 1000,
          }}
        ></div>
      )}
          {isLoading && renderLoadingBackground()}
          {renderRawImageAndMaskIfNeed()}
          {renderToolBarForEditingImage()}
          {renderTopToolBar()}
        </div>
      }
    </div>
  );
});

export default ImageEditingTool;
