import { convertToArrayFromDict } from "./utils";

export const APP_ACTIVE_COLOR = "#215EF9"; 


export const APP_BACKGROUND_COLOR = '#215EF9';

export const AI_CREATIVITY = 'ai-creativity';
export const AI_RENDERING = 'ai-rendering';
export const EDITING = 'editing';
export const AI_AVERTISTMENT = 'ai-advertisement';
export const AI_CAR = 'ai-car';
export const CMF = 'ai-cmf';
export const TAB_LIST = [
  AI_CREATIVITY,
  AI_RENDERING,
  EDITING,
  AI_AVERTISTMENT,
  AI_CAR,
  CMF
];

export const OTHER_CATEGORY = "其他";

const categories_dict = {
  "电饭煲": "Electric Rice Cooker",
  // "空气炸锅": "Air Fryer",
  // "烤箱": "Oven",
  // "压力锅": "Pressure Cooker",
  // "电炖锅": "Electric Stew Pot",
  // "煎药壶": "Medicinal Decoction Pot",
  // "电蒸锅": "Electric Steamer",
  // "电火锅": "Electric Hot Pot",
  // "多功能锅": "Multi-function Pot",
  // "电炒锅": "Electric Stir-fry Pot",
  // "电煮锅": "Electric Boiling Pot",
  // "电热饭盒": "Electric Heating Lunch Box",
  "咖啡机": "Coffee Machine",
  // "磨豆机": "Bean Grinder",
  // "饮水机": "Water Dispenser",
  // "榨汁机": "Juicer",
  // "破壁机": "High-Speed Blender",
  [OTHER_CATEGORY]: "Others",
};

export const PRODUCT_CATEGORIES = convertToArrayFromDict(categories_dict);

export const CHINESE_COMMA = '，';