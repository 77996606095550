import React, {useEffect, useState} from 'react';
import unchecked from '../images/unchecked.svg';
import checked from '../images/checked.png'

import { AI_CREATIVITY, AI_RENDERING, CMF, EDITING, PRODUCT_CATEGORIES, TAB_LIST } from '../constant';
import delete_image_icon from '../images/delete_image.svg';
import CustomSelectWithoutTitle from './CustomSelect/CustomSelectWithoutTitle';
import axios from 'axios';
import { getCurrentDomain, getImagePath } from '../utils';
import BeatLoaderSpinner from './LoadingPage/BeatLoaderSpinner';
import { MOCK_LIBRARAY_DATA } from './mock_library';

// {
//     imageURL: image,
//     id: 1,
//     date: new Date(2024, 2, 1),
//     source: AI_CREATIVITY,
//     category: PRODUCT_CATEGORIES[0].value,
// },

const all_products_type = {
    label: '全部产品',
    value: '全部产品',
}
const NUMBER_OF_IMAGES_PER_PAGE = 20;

const Library = ({
    isAdminAccessUserPage = false,
}) => {

    const [selectedSource, setSelectedSource] = useState('all');
    const [selectedCategory, setSelectedCategory] = useState(all_products_type);
    const [isDeleteMode, setIsDeleteMode] = useState(false);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [libraryData, setLibraryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);

    useEffect(() => {
        const loadAllImages = async ()  =>{
            setLoading(true);
            try{
                let url = getCurrentDomain() + '/get_all_images/all';
                if (isAdminAccessUserPage) {
                    const userName = window.location.pathname.split('/').slice(-1)[0];
                    url = getCurrentDomain() + '/get_all_images_of_user/' + userName;
                }
                const res = await axios.get(url);
                const data = res.data.all_images_in_db;
                setLibraryData(data.map((image) => {
                    return {
                        imageURL: image.imagePath,
                        id: image.id,
                        date: new Date(image.createdTime),
                        source: image.generateType,
                        category: image.imageProductType,
                    }
                }));
                setLoading(false);
            }
            catch (error) {
            
                // setLibraryData(MOCK_LIBRARAY_DATA.map((image) => {
                //     return {
                //         imageURL: image.imagePath,
                //         id: image.id,
                //         date: new Date(image.createdTime),
                //         source: image.generateType,
                //         category: image.imageProductType,
                //     }
                // }));
                setLoading(false);
            }
            
        }  
        loadAllImages();
    
    },[]);


    const renderSourceSelectionButtons = () => {
        return (
            <div style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
            }}>
                {renderButtonSelectAll()}
                {renderButtonSelectAIGeneration()}
                {renderButtonSelectAIRendering()}
                {renderButtonSelectCMF()}
                {renderButtonSelectEditing()}
            </div>
        )
    }

    const renderButtonSelectAll = () => {
        return renderButton({
            text: '全部',
            value: 'all',
            isFirstButton: true,
            isLatestButton: false,
        });
    }

    const renderButtonSelectAIGeneration = () => {
        return renderButton({
            text: 'AI生成',
            value: AI_CREATIVITY,
            isFirstButton: false,
            isLatestButton: false,
        });
    }

    const renderButtonSelectAIRendering = () => {
        return renderButton({
            text: 'AI渲染',
            value: AI_RENDERING,
            isFirstButton: false,
            isLatestButton: false,
        });
    }

    const renderButtonSelectEditing = () => {
        return renderButton({
            text: '编辑',
            value: EDITING,
            isFirstButton: false,
            isLatestButton: true,
        });
    }

    const renderButtonSelectCMF = () => {
        return renderButton({
            text: 'CMF',
            value: CMF,
            isFirstButton: false,
            isLatestButton: true,
        });
    }

    const renderCategorySelectionButtons = () => {
        return (
            <div style={{
                width: 150,
                marginLeft: 310,
            }}>
                <CustomSelectWithoutTitle options={[all_products_type, ...PRODUCT_CATEGORIES]} value={selectedCategory} onChange={setSelectedCategory} />
            </div>
        )
    }

    const deleteImageAPI = async (imagesToDelete) => {
        setLoading(true);
        try {
            const res = await axios.post(getCurrentDomain() + '/delete-images', {
                imageIdList: imagesToDelete.map((image) => image.id),
            });
            console.log(res);
            setLibraryData(libraryData.filter((data) => !imagesToDelete.includes(data)));
            setImagesToDelete([]);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const renderDeleteButton = () => {
        return (
            <div style={{
                width: '150px',
                height: 40,
                borderRadius: 2,
                border: isDeleteMode ? 'none': '1px solid #215EF9',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                color:  isDeleteMode? 'white':'#696969',
                marginLeft: 30,
                marginRight: 85,
                backgroundColor: isDeleteMode ? '#215EF9' : 'white',
                
            }}
            onClick={() => {
                console.log('delete button clicked');
                if (isDeleteMode) {
                    //delete images
                    if (imagesToDelete.length > 0) {
                        console.log('delete images');
                        console.log(imagesToDelete);
                        deleteImageAPI(imagesToDelete);
                    }
                    else {
                        console.log('no image selected');
                        setIsDeleteMode(false);
                    }
                } else {
                    console.log('delete mode');
                    setIsDeleteMode(true);
                }
            }
            }
            >
                {
                    isDeleteMode ? '确定删除' : renderButtonTurnOnDeleteMode()
                }
                
            </div>
        )
    }
    const renderButtonTurnOnDeleteMode = () => {
        return (
        <>
            删除
            <img src={delete_image_icon} style={{
                width: 16,
                height: 16,
                marginLeft: 10,
            }} />
        </>
        )
    }

    const renderButton = ({
        text,
        value,
        isFirstButton,
        isLatestButton,
    }, ) => {
        const isSelected = selectedSource === value;
        return (
            <div style={{
                width: '121px',
                backgroundColor: isSelected ? '#215EF9' : '#E7E7E7',
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: isFirstButton ? '0px' : '2px',
                color: isSelected ? 'white' : '#696969',
                height: 40,
                borderTopLeftRadius: isFirstButton ? 5 : 0,
                borderBottomLeftRadius: isFirstButton ? 5 : 0,
                borderTopRightRadius: isLatestButton ? 5 : 0,
                borderBottomRightRadius: isLatestButton ? 5 : 0

                
            }}
            onClick={() => setSelectedSource(value)}
            >
                {text}
            </div>
        )
    }

    const renderButtonList = () => {
        return (
            <div style={{
                marginTop: 58,
                display: 'flex',
                justifyContent: 'flex-end',
            }}>
                {renderSourceSelectionButtons()}
                {renderCategorySelectionButtons()}
                {renderDeleteButton()}
            </div>
        )
    }

    

    const groupImagesByDate = () => {
        let groupedImages = {};
        libraryData.forEach((data) => {
            
            if (selectedSource === 'all' || data.source === selectedSource) {
                
                if (data.category === selectedCategory.label || selectedCategory.label === all_products_type.label) {
                    const key = `${data.date.getFullYear()}-${data.date.getMonth()}-${data.date.getDate()}`;
                    if (groupedImages[key]) {
                        groupedImages[key].data.push(data);
                    } else {
                        groupedImages[key] = {
                            data: [data],
                            date: data.date,
                        };
                    }
                }
            }
        });
        

        //sort by date
        const sortedKeys = Object.keys(groupedImages).sort((a, b) => {
            return new Date(b) - new Date(a);
        });
        const sortedGroupedImages = {};
        sortedKeys.forEach((key) => {
            sortedGroupedImages[key] = groupedImages[key];
        });
        //convert to array
        groupedImages = Object.values(sortedGroupedImages);

        return groupedImages;
    }

    const sortImagesByDate = (images) => {
        images.sort((a, b) => {
            return b.date - a.date;
        });
        return images;
    }

    const renderImages = () => {
        const imagesWithDate = sortImagesByDate(groupImagesByDate());
        const imagesToDisplay =  getImagesToDisplay(imagesWithDate);
        console.log('imagesToDisplay', imagesToDisplay);
        return imagesToDisplay.map((images) => renderImagesByDate(images));
    }

    const getImagesToDisplay = (imagesWithDate) => {
        const endIndex = currentPageIndex * NUMBER_OF_IMAGES_PER_PAGE;
        let totalGotIamges = 0;
        let result = [];
        for (let i = 0; i < imagesWithDate.length; i++) {
            const numberOfImages = imagesWithDate[i].data.length;
            if (totalGotIamges + numberOfImages <= endIndex) {
                result.push(imagesWithDate[i]);
                totalGotIamges += numberOfImages;
            } else {
                const images = imagesWithDate[i].data.slice(0, endIndex - totalGotIamges);
                result.push({
                    date: imagesWithDate[i].date,
                    data: images,
                });
                break;
            }
        }
        return result;
    }

    const autoPaddingTo2DitgitFormat = (number) => {
        return number < 10 ? `0${number}` : number;
    }

    const renderImagesByDate = (images) => {
        return (
            <div style={{
                marginTop: 30,
                marginLeft: 30,
            }}>
                <div style={{
                    color: '#111111',
                    fontSize: 16,
                    fontWeight: 700
                }}>
                    {images.date.getFullYear()}年{autoPaddingTo2DitgitFormat(images.date.getMonth() + 1)}月{autoPaddingTo2DitgitFormat(images.date.getDate())}日
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    height: 'auto',
                    alignItems: 'flex-start'
                }}>
                    {images.data.map((data) => renderImage(data))}
                </div>
            </div>
        )
    }
    
    const renderImage = (data) => {
        const isSelected = imagesToDelete.includes(data);
        return (
            <div
                key={data.id}
                style={{
                position: 'relative',
                width: 318,
                marginRight: 20,
                marginTop: 20,
            }}>
                <img src={getImagePath(data.imageURL)} style={{
                    width: '100%',
                    height: 'auto',
                }}
                
                />
                {
                    isDeleteMode && renderDeleteImageSelection(data, isSelected)
                 
                }
                {
                    isDeleteMode && renderCheckbox(isSelected)
                }
            </div>
        )
    }

    const renderCheckbox = (isSelected) => {
        return (
            <div style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                width: 20,
                height: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                pointerEvents: 'none',
                
            }}
            >
                <img src={isSelected ? checked : unchecked} style={{
                    width: 20,
                    height: 20,
                }} />
            </div>
        )
    }
    
    const renderDeleteImageSelection = (data, isSelected) => {
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#3D3D3D69',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                padding: 10,
                boxSizing: 'border-box',
                mixBlendMode: 'multiply',
                cursor: 'pointer',
            }}
            onClick={() => {
                if (isSelected) {
                    setImagesToDelete(imagesToDelete.filter((image) => image.id !== data.id));
                } else {
                    setImagesToDelete([...imagesToDelete, data]);
                }
            }}
            
            >
                
            </div>
        )
    }
    
    const renderLoadingPage = () => {
        return (
            <div className='col-12' style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw',
              display: loading ? 'flex' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 10000000000000
            }}>
              <BeatLoaderSpinner />
          </div>
        )
      }

    const renderButtonLoadMore = () => {
        return (
            <div style={{
                marginTop: 30,
                display: 'flex',
                justifyContent: 'center',
                color: 'black',
                cursor: 'pointer',
                display: libraryData.length > currentPageIndex * NUMBER_OF_IMAGES_PER_PAGE ? 'flex' : 'none',
            }}
            onClick={() => {setCurrentPageIndex(currentPageIndex + 1)}}
            >
                加载更多
            </div>
        )
    }

    return (
        <div style={{
            backgroundColor: 'white',
            width: '100%',
        }}>
            {renderButtonList()}
            {renderImages()}
            {renderButtonLoadMore()}
            {renderLoadingPage()}
        </div>
    );

    }


export default Library;