import React from 'react';

const PromptDialog = ({
    title, value, onChange, disabled
}) => {

    return (
        <div className="row prompt-wrapper">
            <div style={{}}>
                {title}
            </div>
            <textarea
                value={value}
                onChange={onChange}
            />
        </div>
    )
};

export default PromptDialog;